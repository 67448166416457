export const SH_GROWTH_STRATEGIES = {
  hero: false,
  title: 'Growth Strategies',
  descr: 'Commonwealth leads multiple growth strategies to maximize return, spark innovation and promote cooperation.',
}

export const SH_ABOUT_US = {
  hero: true,
  title: 'About us',
  descr:
    'Formed by a team with deep knowledge in Web2 and Web3, and a commitment to building and investing in the systems revolutionizing our world.',
}

export const SH_СONTENT = {
  hero: true,
  title: 'Content',
  descr: null,
}
