export const PC_MEMBERS_HOMEPAGE = [
  {
    name: 'Isaac Lidsky',
    position: 'Founding Member, Partner, Senary Ventures',
    about:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget etiam felis habitasse sit neque adipiscing at sit. Sapien proin a, varius vel.',
    img: 'https://i.ibb.co/RvM6xF6/member-1.png',
  },
  {
    name: 'Erich Wasserman',
    position: 'Founding Member, Partner, Senary Ventures',
    about:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget etiam felis habitasse sit neque adipiscing at sit. Sapien proin a, varius vel.',
    img: 'https://i.ibb.co/F8Kw1S5/member-2.png',
  },
  {
    name: 'Jonah Weinstein',
    position: 'Founding Member, Partner, Senary Ventures',
    about:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget etiam felis habitasse sit neque adipiscing at sit. Sapien proin a, varius vel.',
    img: 'https://i.ibb.co/W235J8t/member-3.png',
  },
]

export const PC_MEMBERS_ABOUT_US = [
  {
    name: 'Isaac Lidsky',
    position: 'Founding Member, Partner, Senary Ventures',
    about:
      '<p>6-time tech startup founder. $230m AdTech exit. $200m+ construction services exit. NYT Best Selling Author. TED Talk with 3m+ views. Active, successful PE angel with a focus on technology and healthcare. </p> <p>U.S. Supreme Court clerk (O’Connor and RBG). Harvard Law School, J.D. magna cum laude. Fellow, Berkman Center for Internet and Society. Editor, Harvard Law Review. Harvard College, Math and Computer Science A.B. cum laude.</p>',
    img: 'https://i.ibb.co/RvM6xF6/member-1.png',
  },
  {
    name: 'Erich Wasserman',
    position: 'Founding Member, Partner, Senary Ventures',
    about:
      'MediaMath co-founder with $1B+ revenue in adtech. Led international expansion across EMEA and APAC. Global Chief of Revenue and Head of Corporate Development. <br/>Executive Director of the National Civil Liberties NGO. Active, successful PE angel and strategic advisor in AdTech, FinTech, consumer electronics, and hardware products. <br/> University of Virginia, BA Political Theory and Classical Piano.',
    img: 'https://i.ibb.co/F8Kw1S5/member-2.png',
  },
  {
    name: 'Jonah Weinstein',
    position: 'Founding Member, Partner, Senary Ventures',
    about:
      'Product and technology leader of global HFT systems for digital assets, including financial assets, web identity and media assets. <br/> Web3 native. Early crypto and Web3 investor. Advisor to active crypto and VC funds. <br/> Ithaca College, BS Business Administration',
    img: 'https://i.ibb.co/W235J8t/member-3.png',
  },
]

export const PC_MEMBERS_INFO = {
  title: 'Investment Commitee',
  descr:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget etiam felis habitasse sit neque adipiscing at sit. Sapien proin a, varius vel. Posuere proin ornare sollicitudin sed ultrices. Nulla phasellus faucibus turpis sagittis ac donec id.',
  cta: [
    {
      name: 'About us',
      attrs: {
        to: '/about-us/',
        className: 'btn btn-primary',
      },
    },
  ],
}
